<template>
  <!-- 角色权限分配 -->
  <div>
    <div class="topbox">
      <div class="headerboxs">
        <el-button class="seniorbut2" @click="goBackPage">返回上一页</el-button>
        <el-button
          v-if="
            hasPermiss('zhqx_jsgl:sava:permission') &&
            hasPermiss('zhqx_jsgl:sava:menu')
          "
          class="seniorbut"
          @click="preservation"
          >保存</el-button
        >
      </div>
      <div class="Rolebox">
        <div class="RoleInfobox">角色编号:{{ roleinfo.roleId }}</div>
        <div class="RoleInfobox">角色名称:{{ roleinfo.roleName }}</div>
      </div>
    </div>
    <div class="tablebox">
      <div class="tableleft">
        <div class="tabletitl">功能菜单权限</div>
        <div style="margin-bottom: 10px">
          <el-checkbox v-model="checkedAll" @change="checkAllChange"
            >全选</el-checkbox
          >
        </div>
        <div class="tableen">
          <el-tree
            ref="treeData"
            :data="tableData"
            :props="customProps"
            show-checkbox
            node-key="menuId"
            @check="checkTree"
            :default-expanded-keys="pIds"
            :default-checked-keys="checkedKeysArr"
          >
          </el-tree>
          <!-- :default-checked-keys  注意,只能放子节点key -->
          <!-- :default-expanded-keys  默认展开的节点 -->
          <!-- :default-checked-keys  默认选中的节点 -->

          <!-- <el-table
            ref="dataTreeList"
            @selection-change="handleSelectionChange"
            :data="tableData"
            style="width: 100%; margin-bottom: 20px"
            row-key="menuId"
            border
            default-expand-all
            :tree-props="{ children: 'childMenu' }"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              prop="menuName"
              label="展示名称"
              sortable
              width="500"
            >
            </el-table-column>
          </el-table> -->
        </div>
      </div>
      <div class="tableright">
        <!-- <div class="tabletitl">功能按钮权限</div> -->
        <!-- <div class="tableen">
          <el-table
            ref="multipleTable"
            @selection-change="handleSelectionChange1"
            :data="newPermissArr"
            style="width: 100%; margin-bottom: 20px"
            border
          >
            <el-table-column type="selection" width="60"> </el-table-column>
            <el-table-column
              prop="permissionName"
              label="展示名称"
              sortable
              min-width="200"
            >
            </el-table-column>
          </el-table>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import screenfull from "screenfull";
import tool from "@/assets/js/tool";
export default {
  inject: ["reload"],
  data() {
    return {
      newPermissArr: [],
      roleArray: {
        menuIds: [], //权限id数组
        roleId: "", //要操作的角色id
      },
      multipleSelection: [], //勾选的数据
      roleinfo: "", //角色详情
      tableData: [], //用户菜单
      customProps: {
        // children: "childMenu",
        label: "menuName",
      },
      AllPermissList: [], //登录用户所有的权限
      pIds: [], //当前展开的
      checkedKeysList: [], //当前选中的全部菜单id数组
      checkedKeysArr: [], //当前选中的子菜单id数组
      parentids: [],
      checkedAll: false,
      jspmpList: [],
    };
  },
  mounted() {
    if (this.$route.query.nid) {
      this.roleArray.roleId = this.$route.query.nid;
      this.roleDetail();
      this.getdata();
    } else {
      // this.$message.error("没有检测到角色id");
      // tools.closePage();
      // this.reload();
      // return;
    }
  },
  activated() {
    // if (this.$route.query.nid) {
    //   this.roleArray.roleId = this.$route.query.nid;
    // }
  },
  methods: {
    // 全选
    checkAllChange() {
      if (this.checkedAll) {
        this.$nextTick(() => {
          this.$refs.treeData.setCheckedNodes(this.tableData);
          let arrs = this.getAllIds(this.tableData, []) || [];
          this.checkedKeysList = arrs;
        });
      } else {
        this.$nextTick(() => {
          this.$refs.treeData.setCheckedNodes([]);
          this.checkedKeysList = [];
        });
      }
    },
    //递归获取全部id
    getAllIds(arrs, pids) {
      let ids = pids || [];
      for (var i = 0; i < arrs.length; i++) {
        ids.push(arrs[i].menuId);
        if (arrs[i].children && arrs[i].children.length > 0) {
          this.getAllIds(arrs[i].children, ids);
        }
      }

      return ids;
    },
    getdata() {
      if (!this.roleArray.roleId) {
        return;
      }
      this.getLoginUserRoleMenuPerList();
    },

    // 登录用户的全部菜单和权限
    getLoginUserRoleMenuPerList() {
      Api.getLoginUserRoleMenuPerList().then((res) => {
        if (res.data.status == "success") {
          this.allMenuList = res.data.result.menuVoList || [];
          this.allPmList = res.data.result.permissionList || [];
          this.getRoleMenuPermissionList();
        }
      });
    },

    //角色菜单和权限列表
    getRoleMenuPermissionList() {
      Api.getRoleMenuPermissionList({ roleId: this.roleArray.roleId }).then(
        (res) => {
          let tdata = res.data.result.menuVoList || [];
          let qxdata = res.data.result.permissionList || [];

          this.getMenuList(tdata, qxdata);
          // this.getUserRolePermissionList(qxdata);
        }
      );
    },

    //获取处理后的菜单数组
    getMenuList(tdata, qxdata) {
      let menuList = this.allMenuList || [];
      // 合并
      let concatMenuList = [...menuList, ...tdata];
      let newpIds = [];
      let newMenuList = [];

      // 去重
      for (var i = 0; i < concatMenuList.length; i++) {
        if (newpIds.indexOf(concatMenuList[i].menuId) == -1) {
          newpIds.push(concatMenuList[i].menuId);
          let obj = {
            menuName: concatMenuList[i].menuName + " (菜单)",
            menuId: concatMenuList[i].menuId || "",
            isThisRole: concatMenuList[i].isThisRole || 0,
            number: concatMenuList[i].number || "",
            parentId: concatMenuList[i].parentId,
            jumpUrl: concatMenuList[i].jumpUrl || "",
            icon: concatMenuList[i].icon || "",
            sort: concatMenuList[i].sort || 100,
          };
          newMenuList.push(obj);
        } else {
          if (concatMenuList[i].isThisRole == 1) {
            let idx = newpIds.indexOf(concatMenuList[i].menuId);
            newMenuList[idx].isThisRole = 1;
          }
        }
      }

      let pmList = this.getUserRolePermissionList(qxdata);
      let arr2 = [];
      for (var d = 0; d < pmList.length; d++) {
        let obj = {
          menuName: pmList[d].menuName,
          menuId: pmList[d].menuId,
          isThisRole: pmList[d].isThisRole,
          parentId: pmList[d].parentId,
          number: pmList[d].number,
          sort: 100,
        };
        arr2.push(obj);
      }
      let menuPermissList = [...arr2, ...newMenuList];
      let jspmpList = JSON.parse(JSON.stringify(menuPermissList));

      let checkKeys = [];
      for (var c = 0; c < jspmpList.length; c++) {
        if (jspmpList[c].isThisRole == 1) {
          checkKeys.push(jspmpList[c].menuId);
        }
      }
      this.checkedKeysList = checkKeys;
      this.jspmpList = jspmpList;
      if (checkKeys.length == jspmpList.length) {
        this.checkedAll = true;
      }

      let tabData = this.diGuiGetMeun(
        JSON.parse(JSON.stringify(jspmpList)),
        "-1"
      );

      let parIds = this.getParentIds(tabData);
      let allKeys = JSON.parse(JSON.stringify(checkKeys));
      let pKeys = JSON.parse(JSON.stringify(parIds));
      for (let w = 0; w < allKeys.length; w++) {
        for (let r = 0; r < pKeys.length; r++) {
          if (pKeys[r] == allKeys[w]) {
            allKeys.splice(w, 1);
            w--;
          }
        }
      }
      //
      this.checkedKeysArr = allKeys;
    },
    // 找出父节点
    getParentIds(tabData) {
      tabData.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.parentids.push(item.menuId);
          this.getParentIds(item.children);
        }
      });
      return this.parentids;
    },

    //获取处理后的权限数组
    getUserRolePermissionList(qxdata) {
      let that = this;
      let permissList = JSON.parse(JSON.stringify(this.allPmList)) || [];
      let concatPermissList = [...permissList, ...qxdata];
      let concatArrplist = JSON.parse(JSON.stringify(concatPermissList));
      let newpIds = [];
      let newPermissList = [];

      // 去重
      for (var i = 0; i < concatArrplist.length; i++) {
        if (newpIds.indexOf(concatArrplist[i].permissionId) < 0) {
          newpIds.push(concatArrplist[i].permissionId);
          concatArrplist[i].menuId = concatArrplist[i].menuId || "-1";
          newPermissList.push(concatArrplist[i]);
        } else {
          if (concatArrplist[i].isThisRole == 1) {
            let idx = newpIds.indexOf(concatArrplist[i].permissionId);
            newPermissList[idx].isThisRole = 1;
          }
          if (concatArrplist[i].menuId && concatArrplist[i].menuId != null) {
            let idx = newpIds.indexOf(concatArrplist[i].permissionId);
            newPermissList[idx].menuId = concatArrplist[i].menuId;
          }
        }
      }

      // this.newPermissArr = newPermissList;
      let arr3 = [];
      for (var x = 0; x < newPermissList.length; x++) {
        let obj = {
          menuName: newPermissList[x].permissionName + "(权限)",
          menuId: "p" + newPermissList[x].permissionId,
          isThisRole: newPermissList[x].isThisRole,
          parentId: newPermissList[x].menuId,
          number: newPermissList[x].name,
          sort: "1",
        };
        arr3.push(obj);
      }
      return arr3;

      // that.$nextTick(() => {
      //   for (let i = 0; i < this.newPermissArr.length; i++) {
      //     if (this.newPermissArr[i].isThisRole == 1) {
      //       that.$refs.multipleTable.toggleRowSelection(
      //         that.newPermissArr[i],
      //         true
      //       );
      //     }
      //   }
      // });
    },
    // 勾选复选框
    checkTree(a, b) {
      let checkArr1 = b.checkedKeys || [];
      let checkArr2 = b.halfCheckedKeys || [];
      let checkedArr = [...checkArr1, ...checkArr2];
      this.checkedKeysArr = checkArr1;
      this.checkedKeysList = checkedArr;

      if (this.checkedKeysList.length !== this.jspmpList.length) {
        this.checkedAll = false;
      } else {
        this.checkedAll = true;
      }
    },
    //获取角色详情
    roleDetail() {
      Api.roleDetail({ roleId: this.roleArray.roleId }).then((res) => {
        if (res.data.status == "success") {
          this.roleinfo = res.data.result;
        }
      });
    },
    //获取功能权限id
    handleSelectionChange1(val) {
      let pIds = [];
      for (var i = 0; i < val.length; i++) {
        pIds.push(val[i].permissionId);
      }
      this.pIds = pIds;
    },
    goBackPage() {
      tools.closePage();
      this.reload();
    },
    //保存按钮
    preservation() {
      let _this = this;
      if (this.checkedKeysList.length < 1) {
        this.$message.warning("菜单不能为空");
        return;
      }
      let checkkeysArr = JSON.parse(JSON.stringify(this.checkedKeysList)) || [];
      let menuKeys = [];
      let permissKeys = [];
      for (let i = 0; i < checkkeysArr.length; i++) {
        if (checkkeysArr[i].indexOf("p") == -1) {
          menuKeys.push(checkkeysArr[i]);
        } else {
          permissKeys.push(checkkeysArr[i]);
        }
      }

      let pmArr1 = [];
      permissKeys.forEach((item) => {
        let val = item.replace("p", "");
        pmArr1.push(val);
      });

      //保存菜单
      let param1 = {
        menuIds: menuKeys, //菜单id数组
        roleId: this.roleArray.roleId, //角色id
      };
      let sign1 = tools.getSign(param1);
      param1.sign = sign1;
      Api.saveRoleMenu(param1).then((res) => {
        // 保存权限
        let param2 = {
          menuIds: menuKeys,
          permissionIds: pmArr1, //权限id数组
          roleId: this.roleArray.roleId, //角色id
        };
        let sign2 = tools.getSign(param2);
        param2.sign = sign2;
        Api.saveRolePermission(param2).then((res) => {
          if (res.data.status == "success") {
            this.$message.success(res.data.message || "操作成功");
            setTimeout(() => {
              tools.closePage();
              _this.reload();
            }, 1000);
          }
        });
      });
    },

    //递归获取数据
    diGuiGetMeun(data, parentId) {
      let tree = [];
      let temp;
      data.forEach((item) => {
        if (item.parentId === parentId) {
          let obj = item;
          obj.menuName = item.menuName;
          temp = this.diGuiGetMeun(data, item.menuId);
          if (temp.length > 0) {
            obj.children = temp;
          }
          tree.push(obj);
        }
      });
      //排序
      for (var i = 0; i < tree.length - 1; i++) {
        for (var j = 0; j < tree.length - 1 - i; j++) {
          if (tree[j].sort > tree[j + 1].sort) {
            let temp = tree[j + 1];
            tree[j + 1] = tree[j];
            tree[j] = temp;
          }
        }
      }
      this.tableData = tree;
      return tree;
    },
  },
};
</script>

<style lang="scss" scoped>
.headerboxs {
  width: 100%;
  padding: 4px 0;
  box-sizing: border-box;
  display: flex;

  ::v-deep {
    .el-button {
      padding: 6px 10px;
    }
  }

  .seniorbut2 {
    // padding: 10px 0;
    // width: 100px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #d7d7d7;
  }
}
.Rolebox {
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  .RoleInfobox {
    width: 30%;
    display: flex;
    justify-content: center;
  }
}
.tablebox {
  width: 100%;
  display: flex;
  border-top: 1px solid #d7d7d7;
  // .tableleft {
  //   width: 50%;
  // }
  // .tableright {
  //   width: 50%;
  // }
}
.tabletitl {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.tableen {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  justify-content: center;
}
</style>>